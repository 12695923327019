import { makeAutoObservable, runInAction, toJS } from 'mobx';

import CompanyApi from '../../api/endpoints/CompanyApi';
import { IndustryDTO } from '../../dto/company.types';
import LiteEvent from '../../helpers/LiteEvent';
import LogUtil from '../../helpers/LogUtil';
import { RootStore } from '../RootStore';
import { Company } from './Company';
import { debounce } from 'lodash';

export class CompanyStore {
	rootStore: RootStore;
	companies: Company[] = [];
	companiesNearUser: Company[] = [];
	userLocation: any = undefined;
	industryId: string | undefined;
	industries: IndustryDTO[] = [];
	categories: any = [];
	isLoading: boolean = false;
	hasLoadedAll: boolean = false;

	loadingCompanyWorkspaceIds: string[] = [];
	loadCompaniesFromWorkspaceIdsDebounced: () => void;

	private readonly onCompaniesLoaded = new LiteEvent<any>();

	constructor(rootStore: RootStore) {
		this.loadCompaniesFromWorkspaceIdsDebounced = debounce(this.loadCompaniesFromWorkspaceIds, 500);

		makeAutoObservable(this, { rootStore: false });
		this.rootStore = rootStore;
		this.init();
	}

	init() {
		this.rootStore.userStore.UserIdChanged.on(() => {
			this.loadCompanies();
		});
		this.loadIndustries();
		this.loadCompaniesNearUser();
	}

	get previouslyUsedCompany() {
		return this.companies.find(
			(company) => '' + company.workspaceId === '' + this.rootStore.projectStore.latestProject?.workspaceId
		);
	}

	public get CompaniesLoaded() {
		return this.onCompaniesLoaded.expose();
	}

	get filteredCompanies() {
		if (this.industryId) {
			return this.companies.filter((c) => c.industries.some((i: any) => i.id == this.industryId));
		}

		return this.companies;
	}

	setIndustryId(industryId?: string) {
		this.industryId = industryId;
	}

	findCompanyById(id: string | number) {
		return this.companies.find((company) => '' + company.id === '' + id);
	}

	findCompanyByWorkspaceId(workspaceId: string | number) {
		const company = this.companies.find((company) => '' + company.workspaceId === '' + workspaceId);
		if (company) {
			return company;
		}
		if (workspaceId) {
			this.loadCompanyFromWorkspaceId(workspaceId);
		}

		return undefined;
	}

	loadCompanyFromCompanyId = async (companyId: string) => {
		const result = await CompanyApi.getCompanyFromCompanyId(companyId);
		if (result.statusCode === 200) {
			this.updateCompanyFromServer(result.data);
		}
	};

	loadCompanyFromWorkspaceId = async (workspaceId: string | number) => {
		workspaceId = '' + workspaceId;
		if (this.loadingCompanyWorkspaceIds.indexOf(workspaceId) < 0) {
			this.loadingCompanyWorkspaceIds.push(workspaceId);
			this.loadCompaniesFromWorkspaceIdsDebounced();
			// const result = await CompanyApi.getCompanyFromWorkspaceId(workspaceId);
			// if (result.statusCode === 200) {
			// 	this.updateCompanyFromServer(result.data);
			// }
		}
	};

	private loadCompaniesFromWorkspaceIds = async () => {
		const workspaceIds = toJS(this.loadingCompanyWorkspaceIds);
		const result = await CompanyApi.getCompaniesFromWorkspaceIds(workspaceIds);
		if (result.statusCode === 200) {
			result.data?.forEach((json: any) => this.updateCompanyFromServer(json));
			runInAction(() => {
				const workspaceIds = result.data?.companies?.map((c: any) => c.workspaceId) ?? [];
				this.loadingCompanyWorkspaceIds = this.loadingCompanyWorkspaceIds.filter(
					(id) => !workspaceIds.includes(id)
				);
			});
		}
	};

	findCompanyByName(name: string) {
		return this.companies.find((company) => company.name === name);
	}
	findCompanyByDomain(domain: string) {
		return this.companies.find((company) => company.domain === domain);
	}

	getCompaniesNearPostCode(postCode?: string): Company[] | undefined {
		if (!postCode) {
			return undefined;
		}

		const hits = this.filteredCompanies
			.filter((company) => company.isNear(postCode))
			.sort((a, b) => {
				const aIsNear = a.isNear(postCode);
				const bIsNear = b.isNear(postCode);
				return (aIsNear?.distanceKm || 10000) - (bIsNear?.distanceKm || 10000);
			});
		return hits && hits.length > 0 ? hits : undefined;
	}

	loadIndustries = async () => {
		const result = await CompanyApi.getIndustries();
		if (result.statusCode === 200) {
			runInAction(() => {
				this.industries = result.data.industries ?? [];
				this.categories = result.data.categories ?? [];
			});
		}
	};

	loadCompaniesNearUser = async () => {
		const result = await CompanyApi.loadCompaniesNearUser();
		if (result.statusCode === 200) {
			runInAction(() => {
				this.companiesNearUser = result.data?.companies ?? [];
				this.userLocation = result.data?.location;
			});
		}
	};

	loadCompanies = async (postCode?: string) => {
		this.isLoading = true;

		if (postCode && this.getCompaniesNearPostCode(postCode)) {
			return this.getCompaniesNearPostCode(postCode);
		}

		try {
			const { companies } = this;
			const response = await CompanyApi.loadCompanies(postCode);

			runInAction(() => {
				if (response.statusCode === 200) {
					response.data?.companies?.forEach((json: any) => this.updateCompanyFromServer(json));
				}
				this.isLoading = false;

				if (!this.hasLoadedAll && !postCode) {
					this.hasLoadedAll = true;
				}
				this.onCompaniesLoaded.trigger();
			});

			if (postCode && this.getCompaniesNearPostCode(postCode)) {
				return this.getCompaniesNearPostCode(postCode);
			} else if (!postCode) {
				return companies;
			} else {
				return [];
			}
		} catch (e) {
			runInAction(() => (this.isLoading = false));
			LogUtil.error(e);
		}
	};

	updateCompanyFromServer(json: any) {
		let company = this.companies.find((c) => '' + c.id === '' + json.id);

		if (!company) {
			company = new Company(this, json.id);
			this.companies.push(company);
		}

		if (json.isDeleted) {
			this.removeCompany(company);
		} else {
			company.updateFromJson(json);
			if (company.employees && company.employees.length > 0) {
				company.employees
					.filter((employee) => employee.userId)
					.forEach((employee) => {
						this.rootStore.profileStore.addOrUpdateProfile(employee);
					});
			}
		}
	}

	createCompany() {
		const company = new Company(this);
		this.companies.push(company);
		return company;
	}

	removeCompany(company: Company) {
		this.companies.splice(this.companies.indexOf(company), 1);
		company.dispose();
	}
}
