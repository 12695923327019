import React from 'react';
import ServiceCard, { ServiceCardItem } from './ServiceCard';
import Grid2 from '@mui/material/Unstable_Grid2';
import { HookTypes, withNavigation } from '../../../utils/withHooks';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import EvStationIcon from '@mui/icons-material/EvStation';
import EngineeringIcon from '@mui/icons-material/Engineering';
import TungstenIcon from '@mui/icons-material/Tungsten';
import VideoChatIcon from '@mui/icons-material/VideoChat';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import BoltIcon from '@mui/icons-material/Bolt';
import { Typography } from '@mui/material';
import { TypeAnimation } from 'react-type-animation';
import { observer } from 'mobx-react';
import { makeObservable } from 'mobx';
import { RootStore } from '../../../stores/RootStore';
import StoreContext from '../../../stores/StoreContext';

type Props = HookTypes & {
	category?: string;
};

export enum ServicesEnum {
	// eslint-disable-next-line no-unused-vars
	EnuaCarCharger = 'EnuaCarCharger',
	// eslint-disable-next-line no-unused-vars
	CarCharger = 'CarCharger',
	// eslint-disable-next-line no-unused-vars
	Lighting = 'Lighting',
	// eslint-disable-next-line no-unused-vars
	FreeInspection = 'FreeInspection',
	// eslint-disable-next-line no-unused-vars
	ElCheck = 'ElCheck',
	// eslint-disable-next-line no-unused-vars
	Fusebox = 'Fusebox',
	// eslint-disable-next-line no-unused-vars
	Industry = 'Industry',
	// eslint-disable-next-line no-unused-vars
	Fuses = 'Fuses',
	// eslint-disable-next-line no-unused-vars
	SolarPanels = 'SolarPanels',
	// eslint-disable-next-line no-unused-vars
	Other = 'Other',
	// eslint-disable-next-line no-unused-vars
	Offer = 'Offer',
}

export const services = {
	CarCharger: {
		title: 'Elbillader',
		safeText: 'elbillader',
		description:
			'Hjemmelading av elbil blir bare mer og mer aktuelt. Vi hjelper deg med inspirasjon og informasjon om du vurderer å kjøpe elbillader.',
	},
	ElkoSmart: {
		title: 'Elko Smart',
		safeText: 'strømsparing med Elko Smart',
		description: 'Denne løsningen sparer strøm, styrer varmen og gir inntil 10 000 kroner i Enovastøtte',
	},
	Enua: {
		title: 'Enua Elbillader',
		safeText: 'uforpliktende pristilbud på Enua elbillader med installasjon',
		description:
			'Hjemmelading av elbil blir bare mer og mer aktuelt. Vi hjelper deg med inspirasjon og informasjon om du vurderer å kjøpe elbillader.',
	},
	SolarPanels: {
		title: 'Solceller',
		safeText: 'solceller',
		description:
			// eslint-disable-next-line max-len
			'Vi hjelper deg med å finne den solcelleløsningen som passer best for deg. Vi har lang erfaring med solceller og kan hjelpe deg med alt fra planlegging til installasjon.',
	},
	Lighting: {
		title: 'Lampe, Stikkontakt, Småelektrisk',
		safeText: 'lampe, stikkontakt, eller småelektrisk',
		description:
			'Vi hjelper deg med å bytte ut gamle, og montere nye lamper, stikkontakter og annet du har behov for.',
	},
	FreeInspection: {
		title: 'Gratis Befaring',
		safeText: 'digital befaring',
		description:
			'Vi tilbyr gratis befaring for alle våre kunder. Vi gjennomfører videobefaring, eller kommer hjem til deg og ser på hva som må gjøres.',
	},
	ElCheck: {
		title: 'El-sjekk',
		safeText: 'el-sjekk',
		description: 'Vi utfører el-sjekk og hjelper deg med å få orden på det elektriske anlegget.',
	},
	Fusebox: {
		title: 'Sikringsskap',
		safeText: 'sikringsskap',
		description:
			'Vi hjelper deg med å montere nytt sikringsskap, eller med oppgradering av gamle sikringsskap med skrusikringer.',
	},
	Industry: {
		title: 'Industri',
		safeText: 'industri',
		description: 'El-installasjon, service og vedlikehold for butikker, næringsbygg, industri og annet.',
	},
	Fuses: {
		title: 'Sikringer',
		safeText: 'sikringer',
		description:
			'Trenger du en ny kurs til varmepumpen, eller en ny kurs til garasjen? Vi hjelper deg med det meste.',
	},
	Offer: {
		title: 'Pristilbud',
		safeText: 'pristilbud',
		description: 'Vi gir deg et uforpliktende pristilbud på det du trenger hjelp med.',
	},
	Other: {
		title: 'Annet',
		safeText: 'annet',
		description: 'Vi hjelper deg med alt innen elektrisk arbeid.',
	},
};

const GenericServices = observer(
	class GenericServices extends React.Component<Props> {
		static readonly contextType = StoreContext;
		iconStyle = {
			color: '#224459',
			background: '#fafafa',
			boxShadow: '0 4px 8px rgba(0,0,0,0.26)',
			padding: '0.6rem',
			borderRadius: '50%',
			fontSize: '5rem',
		};

		contrastItems: ServiceCardItem[] = [
			{
				title: services[ServicesEnum.CarCharger].title,
				type: ServicesEnum.CarCharger,
				description: services[ServicesEnum.CarCharger].description,
				buttonTitle: 'Få pris',
				icon: <EvStationIcon sx={this.iconStyle} />,
			},
			{
				title: services[ServicesEnum.Lighting].title,
				type: ServicesEnum.Lighting,
				description: services[ServicesEnum.Lighting].description,
				buttonTitle: 'Belysning',
				icon: <TungstenIcon sx={this.iconStyle} />,
			},
			{
				title: services[ServicesEnum.FreeInspection].title,
				type: ServicesEnum.FreeInspection,
				description: services[ServicesEnum.FreeInspection].description,
				buttonTitle: 'Gratis befaring',
				icon: <VideoChatIcon sx={this.iconStyle} />,
			},
			{
				title: services[ServicesEnum.ElCheck].title,
				type: ServicesEnum.ElCheck,
				description: services[ServicesEnum.ElCheck].description,
				buttonTitle: 'Elsjekk',
				icon: <FactCheckIcon sx={this.iconStyle} />,
			},
			{
				title: services[ServicesEnum.Fusebox].title,
				type: ServicesEnum.Fusebox,
				description: services[ServicesEnum.Fusebox].description,
				buttonTitle: 'Sikringsskap',
				icon: <BoltIcon sx={this.iconStyle} />,
			},
			{
				title: services[ServicesEnum.Industry].title,
				type: ServicesEnum.Industry,
				description: services[ServicesEnum.Industry].description,
				buttonTitle: 'Bedrift',
				icon: <EngineeringIcon sx={this.iconStyle} />,
			},
			{
				title: services[ServicesEnum.SolarPanels].title,
				type: ServicesEnum.SolarPanels,
				description: services[ServicesEnum.SolarPanels].description,
				buttonTitle: 'Få pris',
				icon: <SolarPowerIcon sx={this.iconStyle} />,
			},
			{
				title: services[ServicesEnum.Other].title,
				type: ServicesEnum.Other,
				description: services[ServicesEnum.Other].description,
				buttonTitle: 'Send forespørsel',
				icon: <ElectricalServicesIcon sx={this.iconStyle} />,
			},
		];

		constructor(props: Props) {
			super(props);

			makeObservable(this, {});
		}

		get history() {
			return this.props.navigate!;
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get companyStore() {
			return this.rootStore.companyStore;
		}

		onClick = (item: any) => {
			this.history(`/quote/${item.type}`);
		};

		render() {
			const userLocation = this.companyStore.userLocation?.postArea;
			const companies: any[] = [];
			if (this.companyStore.companiesNearUser.length > 0)
				this.companyStore.companiesNearUser.forEach((company) => {
					companies.push(company.name);
					companies.push(2000);
				});
			return (
				<Grid2 container spacing={2} sx={{ padding: '1rem 0' }} className="GenericServices">
					{userLocation && companies.length > 1 && (
						<Grid2 xs={12} sx={{ textAlign: 'center', color: '#fafafa' }}>
							<Typography variant="h5" color="inherit" textAlign="center">
								<TypeAnimation
									sequence={companies}
									wrapper="span"
									cursor={false}
									repeat={100}
									speed={35}
								/>{' '}
								<br />
								{this.companyStore.companiesNearUser.length - 1 > 0 &&
									`
									og ${this.companyStore.companiesNearUser.length - 1} andre bedrifter nært ${userLocation}`}
								.
							</Typography>
						</Grid2>
					)}

					{this.contrastItems.map((item, index) => (
						<Grid2
							xs={6}
							sm={6}
							md={3}
							sx={{
								textAlign: 'center',
								display: 'flex',
								alignItems: 'stretch',
								justifyContent: 'center',
							}}
							key={`constrast-item-${index}`}
						>
							<ServiceCard item={item} onClick={this.onClick} />
						</Grid2>
					))}
				</Grid2>
			);
		}
	}
);

export default withNavigation(GenericServices);
