import { Typography } from '@mui/material';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { RootStore } from '../../stores/RootStore';
import StoreContext from '../../stores/StoreContext';
import { MessageWidgetType } from '../../types';
import Message from '../../stores/Message';

type Props = {
	message?: Message;
	unreadNum?: number;
};

const LastMessageText = observer(
	class LastMessageText extends React.Component<Props> {
		static readonly contextType = StoreContext;

		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				userStore: computed,
				profileStore: computed,
				senderName: computed,
				isCurrentUserSender: computed,
				isSeenByCurrentUser: computed,
				sender: computed,
				hasColon: computed,
				message: computed,
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get userStore() {
			return this.rootStore.userStore;
		}

		get profileStore() {
			return this.rootStore.profileStore;
		}

		get senderName() {
			const { message } = this.props;

			if (!message) {
				return null;
			}

			if (!message.userId) {
				return '';
			}

			return (
				this.profileStore.getProfileByFilter({
					userId: +message.userId,
					profileId: message.sender?.id ? +message.sender.id : undefined,
				})?.firstName ?? ''
			);
		}

		get isCurrentUserSender() {
			return this.props.message?.userId === this.userStore.currentUserId;
		}

		get isSeenByCurrentUser() {
			return (this.props.unreadNum ?? 0) < 1;
		}

		get sender() {
			const { message } = this.props;

			if (!message) {
				return null;
			}

			if (
				(message.type === 'message' && message.attachments?.length && !message.text) ||
				(message.type === 'widget' && message.widgets?.length && message.widgets[0].type === 'VideoChat')
			) {
				return 'Du';
			}

			return this.isCurrentUserSender ? 'Deg' : this.senderName;
		}

		get hasColon() {
			const { message } = this.props;

			if (!message) {
				return false;
			}

			if (message.type === 'message' && !message.attachments?.length) {
				return Boolean(this.sender);
			} else if (message.type === 'widget' && message.widgets?.length) {
				const widget = message.widgets[0];

				if (!['JoinChannel', 'LeaveChannel', 'VideoChat'].includes(widget.type)) {
					return Boolean(this.sender);
				}
			} else {
				return Boolean(this.sender);
			}

			return false;
		}

		get message() {
			const { message } = this.props;

			if (!message) {
				return 'Ingen meldinger enda';
			}

			if (message.type === 'message') {
				if (message.attachments?.length && !message.text) {
					const mimetypes = message.attachments
						.map((attachment) => attachment.file)
						.map((file) => file.mimetype);

					const fileCount = message.attachments.length;
					const isMultipleFiles = fileCount > 1;

					if (mimetypes.every((mimetype) => mimetype.includes('image'))) {
						return `sendte ${isMultipleFiles ? fileCount : 'et'} bilde${isMultipleFiles ? 'r' : ''}`;
					} else if (mimetypes.every((mimetype) => mimetype.includes('video'))) {
						return `sendte ${isMultipleFiles ? fileCount : 'en'} video${isMultipleFiles ? 'er' : ''}`;
					} else {
						return `sendte ${fileCount} filer`;
					}
				}

				const regex = /@\[(.*?)\]\((user):(.*?)\)/gi;
				const text = message.text.replace(regex, `@$1`);

				let textShort = text.slice(0, 25);
				if (text.length > 40) {
					textShort += '...';
				}

				return textShort;
			} else if (message.type === 'widget' && message.widgets?.length) {
				const widget = message.widgets[0];

				if (['JoinChannel', 'LeaveChannel'].indexOf(widget.type) >= 0) {
					const profile = this.profileStore.getProfile(widget.referenceId?.toString());
					if (widget.type === 'JoinChannel') {
						return `la til ${profile?.name} i kanalen`;
					} else if (widget.type === 'LeaveChannel') {
						return `fjernet ${profile?.name} fra kanalen`;
					}
				}

				if (widget.type === MessageWidgetType.VideoChat) {
					return 'startet en videosamtale';
				} else if (widget.type === MessageWidgetType.ProjectStarRating) {
					return 'sendte en prosjektvurdering';
				} else if (widget.type === MessageWidgetType.EventInvite) {
					return 'sendte en avtaleforespørsel';
				} else if (widget.type === MessageWidgetType.KobbrQuote) {
					return 'sendte et tilbud';
				} else if (widget.type === MessageWidgetType.KobbrQuoteDraft) {
					return 'sendte et utkast til tilbud';
				}

				return widget.type;
			} else {
				// Something needs to be done
				return '-se vedlegg-';
			}
		}

		render() {
			return (
				<Typography
					data-hj-suppress
					className={this.isSeenByCurrentUser ? undefined : 'text--bold'}
					color={this.isSeenByCurrentUser ? 'textSecondary' : 'primary'}
					display="inline"
					variant="body2"
					component="div"
				>
					<b>{this.sender}</b>
					{this.hasColon && ':'} {this.message}
				</Typography>
			);
		}
	}
);

export default LastMessageText;
