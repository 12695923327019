/* eslint-disable no-unused-vars */
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { AutocompleteGetTagProps, Avatar, Chip, InputProps, TextField } from '@mui/material';
import { Profile } from '../../stores/ProfileStore';
import { observer } from 'mobx-react';
import { action, computed, makeObservable } from 'mobx';
import ProfilePicture from './ProfilePicture';

const Root = styled('div')(
	({ theme }) => `
  color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'};
  font-size: 14px;
`
);

const Label = styled('label')(
	() => `
	padding: 0 0 4px;
	line-height: 1.5;
	display: block;
`
);

const InputWrapper = styled('div')(
	({ theme }) => `
  width: 300px;
  border: 1px solid ${theme.palette.mode === 'dark' ? '#434343' : '#d9d9d9'};
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
  }

  &.focused {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
    color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'};
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
	label: string;
	profile: Profile;
}

class Tag extends React.Component<TagProps> {
	render() {
		const { label, onDelete, profile, ...other } = this.props;

		return (
			<div {...other}>
				<Chip
					label={label}
					onDelete={onDelete}
					avatar={
						<Avatar>
							<ProfilePicture profile={profile} noTooltip />
						</Avatar>
					}
				/>
			</div>
		);
	}
}

const StyledTag = styled(Tag)<TagProps>(() => ``);

const Listbox = styled('ul')(
	({ theme }) => `
  width: 100%;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
	
    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

type Props = Pick<InputProps, 'sx'> & {
	profiles?: Profile[];
	selectedProfiles?: Profile | Profile[];
	multiple?: boolean;
	label: string;
	placeholder: string;
	onChange: (profiles: Profile[] | Profile | null) => void;
};

const ProfileInputAutocomplete = observer(
	class ProfileInputAutocomplete extends React.Component<Props> {
		constructor(props: Props) {
			super(props);

			makeObservable(this, {
				profiles: computed,
				renderOption: action,
				renderTags: action,
			});
		}

		get profiles() {
			return this.props.profiles ?? [];
		}

		renderTags = (value: Array<any>, getTagProps: any, ownerState: object) => {
			return value.map((option: Profile, index: number) => (
				<StyledTag label={option.name} profile={option} {...getTagProps({ index })} />
			));
		};

		renderOption = (props: object, option: any, state: object) => (
			<li
				{...props}
				key={`AutocompleteProfile-${option.userId}`}
				style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left' }}
			>
				<div>
					<ProfilePicture profile={option} noTooltip />
					<span style={{ marginLeft: '30px' }}>{option.name}</span>
				</div>
				<CheckIcon fontSize="small" />
			</li>
		);

		onChange = (e: any, values: Profile | Profile[] | null) => {
			this.props.onChange(values);
		};

		render() {
			const { onChange, ...rest } = this.props;

			const defaultValue = this.props.multiple
				? this.props.selectedProfiles ?? []
				: this.props.selectedProfiles ?? null;

			return (
				<Autocomplete
					{...rest}
					multiple={this.props.multiple}
					id="tags-standard"
					options={this.profiles.slice()}
					getOptionLabel={(option: any) => option?.name ?? ''}
					defaultValue={defaultValue}
					onChange={this.onChange}
					isOptionEqualToValue={(option: any, value: any) => option.userId === value.userId}
					ListboxComponent={Listbox}
					fullWidth
					renderInput={(params: any) => (
						<TextField
							{...params}
							variant="outlined"
							label={this.props.label}
							placeholder={this.props.placeholder}
							// size="small"
							sx={{ width: '100%' }}
						/>
					)}
					renderTags={this.renderTags}
					renderOption={this.renderOption}
				/>
			);
		}
	}
);

export default ProfileInputAutocomplete;
