import LogApi from '../api/endpoints/LogApi';
import { ConnectionMetadataExternal } from '../Connection.types';

/**
 * Util class for logging
 */
export default class LogUtil {
	static readonly STORE_DEBUG_LOGGING = false;
	static readonly STORE_INFO_LOGGING = false;
	static readonly STORE_WARNING_LOGGING = false;
	// onlylog errors to server if not localhost
	static readonly STORE_ERROR_LOGGING = window.location.hostname !== 'localhost';

	static getMetadata() {
		const connectionMetadata: ConnectionMetadataExternal = {
			userAgent: navigator?.userAgent,
			platform: navigator?.platform,
			network: {
				downlink: (navigator as any)?.connection?.downlink,
				effectiveType: (navigator as any)?.connection?.effectiveType,
				rtt: (navigator as any)?.connection?.rtt,
			},
			pageVisible: !document.hidden,
		};

		return connectionMetadata;
	}

	static async debug(...params: any[]) {
		if (!(params && params[0])) return;

		console.log(params);
		if (LogUtil.STORE_DEBUG_LOGGING) {
			try {
				await LogApi.logDebug(params, LogUtil.getMetadata());
			} catch (err) {
				// do nothing
			}
		}
	}

	static async info(...params: any[]) {
		if (!(params && params[0])) return;

		console.info(params);
		if (LogUtil.STORE_INFO_LOGGING) {
			try {
				await LogApi.logInfo(params, LogUtil.getMetadata());
			} catch (err) {
				// do nothing
			}
		}
	}

	static async warn(...params: any[]) {
		if (!(params && params[0])) return;

		if (LogUtil.STORE_WARNING_LOGGING) {
			try {
				await LogApi.logWarning(params, LogUtil.getMetadata());
			} catch (err) {
				// do nothing
			}
		}
	}

	static async error(...params: any[]) {
		if (!(params && params[0])) return;

		// @todo: find the cause and fix; Somewhere we are logging an empty object and I can't find it
		if (params.length == 1 && typeof params[0] === 'object' && Object.keys(params[0]).length < 1) {
			return;
		}
		// return if params contains mentino of onesignal
		if (typeof params[0] === 'string' && params[0].indexOf('OneSignal') > -1) {
			return;
		}

		if (LogUtil.STORE_ERROR_LOGGING) {
			try {
				await LogApi.logError(params, LogUtil.getMetadata()).catch((err) => {
					console.info('LogUtil.error', err);
				});
			} catch (err) {
				// do nothing
			}
		}
	}
}


