import { InputAdornment, TextField, TextFieldProps, Typography } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import { observer } from 'mobx-react';
import React from 'react';
import { debounce } from 'lodash';
import ProfileApi from '../../api/endpoints/Profile';
import { ProfileDTO } from '../../dto/profile.types';
import { action, makeObservable } from 'mobx';

type Props = TextFieldProps & {
	// eslint-disable-next-line no-unused-vars
	onFoundProfile?: (profileDto: ProfileDTO) => void;
};

const PhoneNumberTextField = observer(
	class PhoneNumberTextField extends React.Component<Props> {
		countryCode: string = '+47';
		// eslint-disable-next-line no-unused-vars
		findProfileDebounced: (value: string) => void;
		constructor(props: Props) {
			super(props);

			this.findProfileDebounced = debounce((value: string) => this.findProfile(value), 500);

			makeObservable(this, {
				findProfile: action,
				onChange: action,
			});
		}

		findProfile = async (phoneNumber: string) => {
			if (phoneNumber.length === 8 && this.props.onFoundProfile) {
				const response = await ProfileApi.upsertUserFromPhoneNumber('47', phoneNumber);
				if (response.statusCode === 200) {
					if (response.data?.userId) {
						this.props.onFoundProfile(response.data);
						return;
					}
				}
			}
		};

		onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			const phoneNumber = event.target.value;
			if (this.props.onChange) this.props.onChange(event);
			this.findProfileDebounced(phoneNumber);
		};

		render() {
			const { inputProps, InputProps, ...rest } = this.props;

			return (
				<TextField
					autoComplete="tel-national"
					inputProps={{ minLength: 8, maxLength: 8, pattern: '[0-9]{8}', ...inputProps }}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<PhoneIcon color="action" style={{ marginRight: 8 }} />
								<Typography color="textSecondary">{this.countryCode}</Typography>
							</InputAdornment>
						),
						...InputProps,
					}}
					label="Mobilnummer"
					name="phoneNumber"
					type="tel"
					variant="filled"
					{...rest}
					onChange={this.onChange}
				/>
			);
		}
	}
);

export default PhoneNumberTextField;
