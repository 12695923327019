import * as React from 'react';

import { Paper, Typography } from '@mui/material';
import { action, computed, makeObservable, observable } from 'mobx';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MobileStepper from '@mui/material/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { observer } from 'mobx-react';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

type Props = {
	headline?: string;
	interval?: number;
	hideButtons?: boolean;
	children: React.ReactNode[];
};

const AutoplayCarousel = observer(
	class AutoplayCarousel extends React.Component<Props> {
		activeStep: number = 0;
		prevActiveStep: number = 0;

		constructor(props: Props) {
			super(props);
			makeObservable(this, {
				activeStep: observable,
				maxSteps: computed,
				handleStepChange: action,
				setActiveStep: action,
				handleBack: action,
				handleNext: action,
			});
		}

		get maxSteps() {
			return this.props.children?.length ?? 0;
		}

		get interval() {
			return this.props.interval ?? 10000;
		}

		get hideButtons() {
			return this.props.hideButtons ?? false;
		}

		handleStepChange = (step: number) => {
			this.setActiveStep(step);
		};

		setActiveStep = (step: number) => {
			this.activeStep = step;
		};

		handleNext = () => {
			this.prevActiveStep++;
			this.setActiveStep(this.prevActiveStep);
		};

		handleBack = () => {
			this.prevActiveStep--;
			this.setActiveStep(this.prevActiveStep);
		};

		renderMobileStepper() {
			return (
				<MobileStepper
					steps={this.maxSteps}
					position="static"
					sx={{ justifyContent: this.hideButtons ? 'center' : undefined }}
					activeStep={this.activeStep}
					nextButton={
						!this.hideButtons && (
							<Button
								size="small"
								onClick={this.handleNext}
								disabled={this.activeStep === this.maxSteps - 1}
							>
								Neste
								<KeyboardArrowRight />
							</Button>
						)
					}
					backButton={
						!this.hideButtons && (
							<Button size="small" onClick={this.handleBack} disabled={this.activeStep === 0}>
								<KeyboardArrowLeft />
								Tilbake
							</Button>
						)
					}
				/>
			);
		}

		render() {
			const { headline, children } = this.props;
			return (
				<Box sx={{ maxWidth: '100%', flexGrow: 1, marginTop: '1rem' }}>
					{headline && (
						<Paper
							square
							elevation={0}
							sx={{
								display: 'flex',
								alignItems: 'center',
								pl: 2,
								bgcolor: 'transparent',
								marginTop: 1,
							}}
						>
							<Typography>{headline}</Typography>
						</Paper>
					)}
					{this.hideButtons && this.renderMobileStepper()}
					<AutoPlaySwipeableViews
						axis={'x'}
						index={this.activeStep}
						onChangeIndex={this.handleStepChange}
						enableMouseEvents
						interval={this.interval}
					>
						{children.map((child, index) => (
							<div key={index}>{Math.abs(this.activeStep - index) <= 2 ? child : null}</div>
						))}
					</AutoPlaySwipeableViews>
					{!this.hideButtons && this.renderMobileStepper()}
				</Box>
			);
		}
	}
);

export default AutoplayCarousel;
