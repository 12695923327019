import './index.scss';

import App from './components/App';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import React from 'react';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { RootStore } from './stores/RootStore';
import ScrollToTop from './components/ScrollToTop';
import StoreContext from './stores/StoreContext';
import ThemeProvider from './themes/ThemeProvider';
import { createRoot } from 'react-dom/client';
import LogUtil from './helpers/LogUtil';
import ErrorBoundary from './utils/ErrorBoundary';

const container = document.getElementById('root');

const root = createRoot(container!);
root.render(
	<React.StrictMode>
		<ErrorBoundary>
			<StoreContext.Provider value={new RootStore()}>
				<ThemeProvider>
					<BrowserRouter>
						<ScrollToTop />
						<CssBaseline />
						<App />
					</BrowserRouter>
				</ThemeProvider>
			</StoreContext.Provider>
		</ErrorBoundary>
	</React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

const originalConsoleError = console.error;
console.error = (...params) => {
	originalConsoleError(...params);
	LogUtil.error(...params);
	// throw new Error(`Error: ${params}`);
};

if ('serviceWorker' in navigator) {
	navigator.serviceWorker
		.getRegistration()
		.then((registration) => {
			if (registration?.waiting) {
				console.log(`Skipping wait`);
				registration?.waiting?.postMessage({ type: 'SKIP_WAITING' });
			}
		})
		.catch(console.error);
}
