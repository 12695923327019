import { Address, ProfileType, UserSettingsMinimalDto } from '../../types';
import AsyncStorageHelper from '../../auth/AsyncStorageHelper';
import config from '../../config/config';
import { FetchWrapper, FetchWrapperResponse, FetchWrapperTypedResponse } from '../../fetch/FetchWrapper';
import { ProfileDTO } from '../../dto/profile.types';

/**
 * Profile api
 */
export default class ProfileApi {
	/**
	 * Load profiles
	 */
	static async loadProfilesByProfileIds(profileIds: number[]): Promise<FetchWrapperTypedResponse<ProfileDTO[]>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/profile/profiles`;

		return FetchWrapper.typedPost<ProfileDTO[]>(url, {
			body: JSON.stringify({ profileIds }),
		});
	}

	/**
	 * Upsert user from email
	 * @param {String} email
	 * @param {String} name
	 * @returns {Promise<FetchWrapperTypedResponse<ProfileDTO>>}
	 */
	static async upsertUserFromEmail(email: string, name?: string): Promise<FetchWrapperTypedResponse<ProfileDTO>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/profile/upsert-from-email`;

		return FetchWrapper.typedPost<ProfileDTO>(url, {
			body: JSON.stringify({
				email,
				name,
			}),
		});
	}

	/**
	 * Upsert user from phone number
	 * @param {String} phoneNumber include country code, if not, +47 will be added
	 * @param {String} name
	 * @param {String} email
	 *
	 */
	static async upsertUserFromPhoneNumber(
		countryCode: string, // no leading +
		phoneNumber: string,
		name?: string,
		email?: string
	): Promise<FetchWrapperTypedResponse<ProfileDTO>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/profile/upsert-from-phonenumber`;

		return FetchWrapper.typedPost<ProfileDTO>(url, {
			body: JSON.stringify({
				countryCode,
				phoneNumber,
				name,
				email,
			}),
		});
	}

	/**
	 * Load profiles
	 * @param {String[]} userIds
	 */
	static async lookupPhoneNumber(phoneNumber: string): Promise<any> {
		try {
			const befareJWT = await AsyncStorageHelper.getCachedBefareJWT();
			const host = config.hosts.api;
			const url = `//${host}/v1/profile/lookup/phonenumber/${phoneNumber}`;

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${befareJWT}`,
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
			});

			if (response.status !== 200) {
				throw new Error(
					`looking up details for phone number ${phoneNumber} failed. ${response.status} for ${url}`
				);
			}

			return await response.json();
		} catch (error: any) {
			throw new Error(error);
		}
	}

	/**
	 * Load profiles
	 * @param {String} userId
	 */
	static async getIsOnline(userId: string): Promise<Object[]> {
		try {
			const befareJWT = await AsyncStorageHelper.getCachedBefareJWT();
			const host = config.hosts.api;
			const url = `//${host}/v1/profile/is-online${userId ? `/${userId}` : ''}`;

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${befareJWT}`,
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
			});

			return await response.json();
		} catch (error: any) {
			throw new Error(error);
		}
	}

	/**
	 * Load profiles
	 * @param {String[]} userIds
	 */
	static async loadWorkspaceProfiles(
		userIds: { userId: string; workspaceId?: string }[]
	): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/profile/workspace`;

		return FetchWrapper.typedPost(url, {
			body: JSON.stringify({ userIds }),
		});
	}

	/**
	 * Load profiles
	 * @param {String[]} userIds
	 */
	static async loadProfiles(userIds: string[]): Promise<Object[]> {
		try {
			const befareJWT = await AsyncStorageHelper.getCachedBefareJWT();
			const host = config.hosts.api;
			const url = `//${host}/v1/profile`;

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${befareJWT}`,
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					userIds: userIds,
				}),
			});

			return await response.json();
		} catch (error: any) {
			throw new Error(error);
		}
	}

	/**
	 * Load profiles
	 * @param {String[]} userIds
	 */
	static async loadUsersProfile(): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/profile`;

		return FetchWrapper.typedGet(url, {
			requireAuth: true,
		});
	}

	static async updateUsersProfile(profile: Partial<ProfileType>): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/profile/update`;

		return FetchWrapper.typedPost(url, {
			body: JSON.stringify({
				profile: profile,
			}),
			requireAuth: true,
		});
	}

	static async updateColleagueProfile(profile: Partial<ProfileType>): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/profile/update/colleague`;

		return FetchWrapper.typedPost(url, {
			body: JSON.stringify({
				profile: profile,
			}),
			requireAuth: true,
		});
	}

	/**
	 * Load profiles
	 * @param {String[]} userIds
	 */
	static async saveUsersAddress(address: Address): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/profile/address`;

		return FetchWrapper.typedPost(url, {
			body: JSON.stringify({
				address: address,
			}),
		});
	}

	/**
	 * Load profiles
	 * @param {String[]} userIds
	 */
	static async getUsersAddress(): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/profile/address`;

		return FetchWrapper.typedGet(url);
	}

	static async deleteUsersAddress(address: Address): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/profile/address/${address.id}`;

		return FetchWrapper.delete(url);
	}

	/**
	 * Load profiles
	 */
	static async loadColleagues(): Promise<FetchWrapperResponse> {
		const host = config.hosts.api;
		const url = `//${host}/v1/profile/colleagues`;

		return FetchWrapper.typedGet(url);
	}

	/**
	 * Load settings
	 */
	static async loadSettings(): Promise<FetchWrapperTypedResponse<UserSettingsMinimalDto>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/profile/settings`;

		return FetchWrapper.typedGet<UserSettingsMinimalDto>(url);
	}

	/**
	 * upsert settings
	 */
	static async upsertSettings(
		settingsDto: UserSettingsMinimalDto
	): Promise<FetchWrapperTypedResponse<UserSettingsMinimalDto>> {
		const host = config.hosts.api;
		const url = `//${host}/v1/profile/settings`;

		return FetchWrapper.typedPost<UserSettingsMinimalDto>(url, {
			body: JSON.stringify({
				settingsDto,
			}),
		});
	}
}
