import {
	Box,
	Button,
	Card,
	CardActionArea,
	CardMedia,
	IconButton,
	ImageListItemBar,
	MobileStepper,
	Paper,
	Stack,
	Typography,
} from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { action, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { File } from '../../../stores/FileStore';
import StoreContext from '../../../stores/StoreContext';
import ProfilePicture from '../ProfilePicture';
import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';
import './FilePreview.scss';

import { RootStore } from '../../../stores/RootStore';
const PdfViewer = React.lazy(() => import('./PdfViewer'));

const Controls = () => {
	const { zoomIn, zoomOut, resetTransform } = useControls();
	const zoomInF = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		zoomIn();
	};

	const zoomOutF = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		zoomOut();
	};

	const resetF = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		resetTransform();
	};
	return (
		<Stack
			className="tools"
			sx={{
				position: 'absolute',
				top: 0,
				right: 0,
				zIndex: 10,
			}}
		>
			<IconButton onClick={zoomInF}>
				<ZoomInIcon />
			</IconButton>
			<IconButton onClick={zoomOutF}>
				<ZoomOutIcon />
			</IconButton>
			<IconButton onClick={resetF}>
				<RestartAltIcon />
			</IconButton>
		</Stack>
	);
};

type FilePreviewCardProps = {
	// eslint-disable-next-line no-unused-vars
	onClick: (file: File) => void;
	file: File;
	children?: React.ReactNode;
};

const FilePreviewCard = observer(
	class FilePreviewCard extends React.Component<FilePreviewCardProps> {
		onClick = () => {
			const { onClick, file } = this.props;
			onClick(file);
		};
		render() {
			const { file, children } = this.props;
			const title = file?.name;
			const date = new Date(file?.created).toLocaleString(undefined, {
				day: 'numeric',
				month: 'long',
				year: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
			});
			return (
				<Card className={'FilePreview FilePreview--card'}>
					<CardActionArea className="preview" data-hj-suppress onClick={this.onClick}>
						{children}
						<ImageListItemBar title={title} subtitle={date} />
					</CardActionArea>
				</Card>
			);
		}
	}
);

type FilePreviewProps = {
	files: any[];
	dialogMode?: boolean;
	// eslint-disable-next-line no-unused-vars
	onFileClick?: (file: File) => void;
};

const FilePreview = observer(
	class FilePreview extends React.Component<FilePreviewProps> {
		static readonly contextType = StoreContext;

		numPdfPages: number | undefined;

		constructor(props: FilePreviewProps) {
			super(props);

			makeObservable(this, {
				files: computed,
				rootStore: computed,
				uiState: computed,
				profileStore: computed,
				currentFilePreviewById: computed,
				currentIndex: computed,
				getSender: action,
				handleOnChangeIndex: action,
				nextSlide: action,
				prevSlide: action,
			});
		}

		get files() {
			return this.props.files.slice().sort((a: any, b: any) => {
				if (a.created > b.created) return -1;
				if (a.created < b.created) return 1;
				return 0;
			});
		}

		get rootStore() {
			return this.context as RootStore;
		}

		get uiState() {
			return this.rootStore.uiState;
		}

		get profileStore() {
			return this.rootStore.profileStore;
		}

		get currentFilePreviewById() {
			return this.uiState.currentFilePreviewById;
		}

		get currentIndex(): number {
			return this.files.findIndex((file) => '' + file.id === '' + this.currentFilePreviewById) ?? 0;
		}

		getSender(userId: string) {
			const test: any = this.profileStore.getProfile(userId);
			return test;
		}

		handleOnChangeIndex = (index: number) => {
			const fileId: string = this.files[index].id;
			this.uiState.setCurrentFilePreviewIndex(fileId);
		};

		handleClick = (file: File) => {
			this.uiState.setCurrentFilePreviewIndex(file?.id);
			if (this.props.onFileClick) {
				this.props.onFileClick(file);
			}
		};

		nextSlide = () => {
			const nextIndex = this.currentIndex === this.files.length - 1 ? 0 : this.currentIndex + 1;
			this.uiState.setCurrentFilePreviewIndex(this.files[nextIndex].id);
		};

		prevSlide = () => {
			const nextIndex: number = this.currentIndex === 0 ? this.files.length - 1 : this.currentIndex - 1;
			this.uiState.setCurrentFilePreviewIndex(this.files[nextIndex].id);
		};

		onPdfDocumentLoadSuccess = (pdfData: any) => {
			console.info(pdfData);
			this.numPdfPages = pdfData.numPages;
		};

		downloadFile = (file: File) => {
			if (!file) {
				return;
			}

			const link = document.createElement('a');

			link.target = '_blank';
			link.download = file.name;

			link.href = file.path;
			document.body.appendChild(link);
			link.click();

			// Cleanup the DOM
			document.body.removeChild(link);
		};

		wrapInTransformWrapper = (children: React.ReactNode) => {
			if (!this.props.dialogMode) {
				return children;
			}
			return (
				<TransformWrapper initialScale={1}>
					<Controls />
					<TransformComponent>{children}</TransformComponent>
				</TransformWrapper>
			);
		};

		renderFilePreview = (file: File) => {
			if (!file) {
				return;
			}

			switch (file.mimetype) {
				case 'image/png':
				case 'image/webp':
				case 'image/jpg':
				case 'image/jpeg':
				case 'image/gif':
				case 'image/svg':
				case 'image/heif':
				case 'image/heic':
					return this.wrapInTransformWrapper(
						<CardMedia
							component="img"
							image={this.props.dialogMode ? file.path : file.preview ? file.preview : file.path}
							title={file.name}
							className={this.props.dialogMode ? 'card-media-dialog' : 'card-media-preview'}
						/>
					);
				case 'video/mp4':
				case 'video/webm':
				case 'video/quicktime':
					return this.props.dialogMode ? (
						<CardMedia
							component="video"
							controls
							className={this.props.dialogMode ? 'card-media-dialog' : 'card-media-preview'}
						>
							<source src={file?.path} type="video/mp4" />
						</CardMedia>
					) : (
						<div style={{ position: 'relative', display: 'inline-block', cursor: 'pointer' }}>
							<CardMedia
								component="video"
								className={this.props.dialogMode ? 'card-media-dialog' : 'card-media-preview'}
								src={file?.path}
							></CardMedia>
						</div>
					);
				case 'application/pdf':
					return (
						<Box
							display="flex"
							alignItems="center"
							justifyContent="center"
							color="primary.main"
							bgcolor="rgba(0, 0, 0, .08)"
							height="100%"
							width="100%"
						>
							<PdfViewer file={file} simple={Boolean(!this.props.dialogMode)} />
						</Box>
					);
				default:
					return (
						<Box
							display="flex"
							alignItems="center"
							justifyContent="center"
							color="primary.main"
							bgcolor="rgba(0, 0, 0, .08)"
							height="100%"
						>
							<span className="material-icons md-48">file_present</span>
						</Box>
					);
			}
		};

		renderFilesInDialogMode() {
			const theme = {
				direction: '',
			};
			const file = this.files[this.currentIndex];

			const disabled = this.currentIndex === this.files.length - 1;
			return (
				<Box
					sx={{
						height: '100%',
						overflow: 'hidden',
						display: 'flex',
						flexShrink: 0,
						width: '100%',
						flexDirection: 'column',
					}}
					className="FilePreview"
				>
					<Box data-hj-suppress sx={{ height: '64px' }}>
						<Paper square elevation={0} className={'header'}>
							<ProfilePicture profile={this.getSender(file?.userId)} />
							{this.getSender(file?.userId)?.name && (
								<div style={{ flexGrow: 1 }}>
									<Typography variant="subtitle2" className={'file-author-name'}>
										{this.getSender(file?.userId)?.name}
									</Typography>
									<Typography variant="caption" className={'file-author-name'}>
										{new Date(file?.created).toLocaleString(undefined, {
											day: 'numeric',
											month: 'long',
											year: 'numeric',
											hour: 'numeric',
											minute: 'numeric',
										})}
									</Typography>
								</div>
							)}

							<Button
								variant="contained"
								size="small"
								startIcon={<CloudDownloadIcon />}
								onClick={() => this.downloadFile(file)}
							>
								Last ned
							</Button>
						</Paper>
					</Box>
					<Box
						sx={{
							overflow: 'scroll',
							flexGrow: '1',
							position: 'relative',
							justifyContent: 'center',
							alignItems: 'center',
							display: 'flex',
						}}
					>
						{this.renderFilePreview(file)}
					</Box>
					<Box sx={{ height: '64px' }}>
						<MobileStepper
							steps={this.files.length}
							position="static"
							variant="text"
							activeStep={this.currentIndex}
							nextButton={
								<Button size="small" onClick={this.nextSlide} disabled={disabled}>
									Neste
									{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
								</Button>
							}
							backButton={
								<Button onClick={this.prevSlide} disabled={this.currentIndex === 0}>
									{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
									Tilbake
								</Button>
							}
						/>
					</Box>
				</Box>
			);
		}

		renderFile() {
			return this.files.map((file: File) => (
				<FilePreviewCard key={`FILE-${file.id}`} file={file} onClick={this.handleClick}>
					{this.renderFilePreview(file)}
				</FilePreviewCard>
			));
		}

		render() {
			if (this.props.dialogMode) {
				return this.renderFilesInDialogMode();
			} else {
				return this.renderFile();
			}
		}
	}
);

export default FilePreview;
